.header-sticky .presco_icon_language {
    content: url(../../assets/images/language.svg);
}
.background-header .presco_icon_language {
    content: url(../../assets/images/icons-dark/language.svg);
}

.header-sticky .presco_menu_icon {
    content: url(../../assets/images/icons/menu_icon.svg);
}
.background-header .presco_menu_icon {
    content: url(../../assets/images/icons-dark/menu_icon.svg);
}




.head-section-actions .head-section-action-item:hover .item-icon-box_icon {
    content: url(../../assets/images/icons/box_icon.svg);
}
.head-section-actions .head-section-action-item .item-icon-box_icon {
    content: url(../../assets/images/icons-dark/box_icon.svg);
}

.head-section-actions .head-section-action-item:hover .item-icon-container_icon {
    content: url(../../assets/images/icons/container_icon.svg);
}
.head-section-actions .head-section-action-item .item-icon-container_icon {
    content: url(../../assets/images/icons-dark/container_icon.svg);
}


.head-section-actions .head-section-action-item:hover .item-icon-document_icon {
    content: url(../../assets/images/icons/document_icon.svg);
}
.head-section-actions .head-section-action-item .item-icon-document_icon {
    content: url(../../assets/images/icons-dark/document_icon.svg);
}


.company-item .company-item-icon .company-item-icon-white_safe_icon {
    content: url(../../assets/images/icons/white_safe_icon.svg);
}
.company-item:hover .company-item-icon .company-item-icon-white_safe_icon {
    content: url(../../assets/images/icons-dark/white_safe_icon.svg);
}
.company-item .company-item-icon .company-item-icon-dark_car_icon {
    content: url(../../assets/images/icons/dark_car_icon.svg);
}
.company-item:hover .company-item-icon .company-item-icon-dark_car_icon {
    content: url(../../assets/images/icons-dark/dark_car_icon.svg);
}
.company-item .company-item-icon .company-item-icon-dark_box_icon {
    content: url(../../assets/images/icons/dark_box_icon.svg);
}
.company-item:hover .company-item-icon .company-item-icon-dark_box_icon {
    content: url(../../assets/images/icons-dark/dark_box_icon.svg);
}
.company-item .company-item-icon .company-item-icon-dark_route_icon {
    content: url(../../assets/images/icons/dark_route_icon.svg);
}
.company-item:hover .company-item-icon .company-item-icon-dark_route_icon {
    content: url(../../assets/images/icons-dark/dark_route_icon.svg);
}


.head-section-action-item .vacansy-img .vacansy-img-personalcard_icon {
    content: url(../../assets/images/icons/personalcard_icon.svg);
}
.head-section-action-item:hover .vacansy-img .vacansy-img-personalcard_icon {
    content: url(../../assets/images/icons/personalcard_icon.svg);
}
.head-section-action-item .vacansy-img .vacansy-img-chart_icon {
    content: url(../../assets/images/icons/chart_icon.svg);
}
.head-section-action-item:hover .vacansy-img .vacansy-img-chart_icon {
    content: url(../../assets/images/icons-dark/chart_icon.svg);
}
.head-section-action-item .vacansy-img .vacansy-img-money_icon {
    content: url(../../assets/images/icons/money_icon.svg);
}
.head-section-action-item:hover .vacansy-img .vacansy-img-money_icon {
    content: url(../../assets/images/icons-dark/money_icon.svg);
}
.head-section-action-item .vacansy-img .vacansy-img-coin_icon {
    content: url(../../assets/images/icons/coin_icon.svg);
}
.head-section-action-item:hover .vacansy-img .vacansy-img-coin_icon {
    content: url(../../assets/images/icons-dark/coin_icon.svg);
}

