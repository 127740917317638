@import "helper/fonts.scss";
@import "helper/checkbox.scss";
@import "helper/modal.scss";
@import "helper/icons.scss";
@import "helper/slick-slider.scss";







body::-webkit-scrollbar-track {
  background: #fff;
  width: 0;
  height: 0;
}
body::-webkit-scrollbar-thumb {
  background: #e4e9f2;
  cursor: pointer;
  border-radius: 0.15625rem;
}
body::-webkit-scrollbar {
  width: 0;
  height: 0;
}
.bold {
  font-weight: bold !important;
}
/* BASE VARIABLES */
.title {
    font-family: 'Jost';
    font-style: normal;
    font-weight: bold;
    font-size: 55px;
    line-height: 67px;
    text-align: center;
    }
    .red {
        color: #E12A31;
    }
    .black {
      color: #17171A;
    }
    .italic {
      font-style: italic;
    }
   .description {
      justify-content: center;
      text-align: center;
      font-family: 'Jost';
      font-style: normal;
      font-weight: normal;
      font-size: 22px;
      line-height: 32px;
    }
  
    @media (max-width: 768px) {
      .title {
        font-size: 30px;
      }
      .description {
        font-size: 18px;
      }
    }
  
  /* 
  ---------------------------------------------
  header
  --------------------------------------------- 
  */
  a {
    text-decoration: none !important; 
  }
  
  
  .background-header {
    background-color: #fff;
    height: 80px!important;
    position: fixed!important;
    top: 0px;
    left: 0px;
    right: 0px;
    box-shadow: 0px 0px 10px rgba(0,0,0,0.15) !important;
  }
  
  .background-header .logo,
  .background-header .main-nav .nav li a {
    color: #1e1e1e!important;
  }
  
  .background-header .main-nav .nav li:hover a {
    color: #E12A31!important;
  }
  
  .background-header .nav li a.active {
    color: #E12A31!important;
  }
  
  .header-area {
    position: absolute;
    top: 0px;
    left: 0px;
    right: 0px;
    z-index: 100;
    height: 100px;
    -webkit-transition: all .5s ease 0s;
    -moz-transition: all .5s ease 0s;
    -o-transition: all .5s ease 0s;
    transition: all .5s ease 0s;
  }
  
  .header-area .main-nav {
    min-height: 80px;
    background: transparent;
  }
  @media (min-width: 769px) {
    .header-area .main-nav {
    display: flex;
    justify-content: center;
    align-items: center;
    }
  }
  
  .header-area .main-nav .logo {
    margin-right: 43px;
    transition: all 0.3s ease 0s;
    display: flex;
    align-items: center;
    height: 80px;
  }
  
  
  .header-area .main-nav .nav {
    float: right;
    /* margin-top: 27px; */
    margin-right: 0px;
    background-color: transparent;
    -webkit-transition: all 0.3s ease 0s;
    -moz-transition: all 0.3s ease 0s;
    -o-transition: all 0.3s ease 0s;
    transition: all 0.3s ease 0s;
    position: relative;
    z-index: 999;
  }
  
  .header-area .main-nav .nav .scroll-to-section, .submenu {
    padding-left: 20px;
    padding-right: 20px;
    height: 80px;
    display: flex;
    align-items: center;
    @media (max-width: 768px) {
      height: unset;
      .submenu-dropdown {
        &.active {
          height: auto;
        }
      }
    }
  }
  
  .header-area .main-nav .nav .search-icon {
    float: right;
    margin-left: 15px;
  }
  
  .header-area .main-nav .nav .search-icon a {
    background-color: #fff;
    color: #5fb759;
    width: 40px;
    height: 40px;
    display: inline-block;
    text-align: center;
    line-height: 40px;
  }
  
  @media (max-width: 768px) {
    .submenu {
      padding: 5px 20px !important;
    }
    .header-area .main-nav .nav .search-icon {
      float: none;
      text-align: center;
      width: 100%;
      background-color: #fff;
      margin-left: 0px;
    }
  }
  
  .header-area .main-nav .nav .search-icon a:hover {
    opacity: 1;
    color: #5fb759!important;
  }
  
  .header-area .main-nav .nav li a {
    display: block;
    font-weight: 500;
    font-size: 13px;
    color: #7a7a7a;
    transition: all 0.3s ease 0s;
    border: transparent;
    font-family: 'Jost';
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    text-align: center;
  }
  
  .header-area .main-nav .nav li a {
    color: #fff;
  }
  
  .header-area .main-nav .nav li:hover a,
  .header-area .main-nav .nav li a.active {
    font-size: 16px;
    // text-align: center;
    color: #E12A31;
  }
  
  .background-header .main-nav .nav li:hover a,
  .background-header .main-nav .nav li a.active {
    color: #E12A31!important;
    opacity: 1;
  }
  
  .header-area .main-nav .nav li.submenu {
    position: relative;
  }
  
  
  
  .background-header .main-nav .nav li.submenu:after {
    color: #7a7a7a;
  }
  
  .header-area .main-nav .nav li.submenu ul {
    padding: 20px;
    position: absolute;
    box-shadow: 0 2px 28px 0 rgba(0, 0, 0, 0.06);
    overflow: hidden;
    top: 60px;
    opacity: 0;
    transform: translateY(+2em);
    visibility: hidden;
    z-index: -1;
    transition: all 0.3s ease-in-out 0s, visibility 0s linear 0.3s, z-index 0s linear 0.01s;
    list-style-type: none;
    width: 240px;
  }
  
  /* .header-area .main-nav .nav li.submenu ul li {
    padding: 5px 0;
  } */
  
  .header-area .main-nav .nav li.submenu ul li a {
    opacity: 1;
    display: block;
    transition: all 0.3s ease 0s;
    position: relative;
    font-size: 14px;
    text-align: left !important;
    color: white !important;
    font-family: 'Jost' !important;
  }
  
  .header-area .main-nav .nav li.submenu ul li a:hover {
    color: #e12a31 !important;
    padding-left: 1px;
  }
  
  .header-area .main-nav .nav li.submenu ul li a:hover:before {
    width: 3px;
  }
  
 @media (min-width: 769px) {
  .header-area .main-nav .nav li.submenu:hover ul {
    visibility: visible;
    opacity: 1;
    z-index: 1;
    transform: translateY(0%);
    transition-delay: 0s, 0s, 0.3s;
    padding: 20px;
    color: black;
    width: 240px;
    background: #FFFFFF;
    box-shadow: 10px 10px 24px #00000026;
    border-radius: 5px;
    list-style-type: none !important;
  }
  .header-area .main-nav .nav li.submenu:hover ul li  {
    padding: 0;
  }
  .header-area .main-nav .nav li.submenu:hover ul li a  {
    margin-top: 3px;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    color: #17171A !important;
  }
 } 

  .header-area .main-nav .menu-trigger {
    cursor: pointer;
    display: block;
    position: absolute;
    top: 23px;
    width: 32px;
    height: 40px;
    text-indent: -9999em;
    z-index: 99;
    right: 40px;
    display: none;
  }
  
  .header-area .main-nav .menu-trigger span,
  .header-area .main-nav .menu-trigger span:before,
  .header-area .main-nav .menu-trigger span:after {
    -moz-transition: all 0.4s;
    -o-transition: all 0.4s;
    -webkit-transition: all 0.4s;
    transition: all 0.4s;
    background-color: #FFF;
    display: block;
    position: absolute;
    width: 30px;
    height: 2px;
    left: 0;
  }
  
  .background-header .main-nav .menu-trigger span,
  .background-header .main-nav .menu-trigger span:before,
  .background-header .main-nav .menu-trigger span:after {
    background-color: #1e1e1e;
  }
  
  .header-area .main-nav .menu-trigger span:before,
  .header-area .main-nav .menu-trigger span:after {
    -moz-transition: all 0.4s;
    -o-transition: all 0.4s;
    -webkit-transition: all 0.4s;
    transition: all 0.4s;
    background-color: #FFF;
    display: block;
    position: absolute;
    width: 30px;
    height: 2px;
    left: 0;
    width: 75%;
  }
  
  .background-header .main-nav .menu-trigger span:before,
  .background-header .main-nav .menu-trigger span:after {
    background-color: #1e1e1e;
  }
  
  .header-area .main-nav .menu-trigger span:before,
  .header-area .main-nav .menu-trigger span:after {
    content: "";
  }
  
  .header-area .main-nav .menu-trigger span {
    top: 16px;
  }
  
  .header-area .main-nav .menu-trigger span:before {
    -moz-transform-origin: 33% 100%;
    -ms-transform-origin: 33% 100%;
    -webkit-transform-origin: 33% 100%;
    transform-origin: 33% 100%;
    top: -10px;
    z-index: 10;
  }
  
  .header-area .main-nav .menu-trigger span:after {
    -moz-transform-origin: 33% 0;
    -ms-transform-origin: 33% 0;
    -webkit-transform-origin: 33% 0;
    transform-origin: 33% 0;
    top: 10px;
  }
  
  .header-area .main-nav .menu-trigger.active span,
  .header-area .main-nav .menu-trigger.active span:before,
  .header-area .main-nav .menu-trigger.active span:after {
    background-color: transparent;
    width: 100%;
  }
  
  .header-area .main-nav .menu-trigger.active span:before {
    -moz-transform: translateY(6px) translateX(1px) rotate(45deg);
    -ms-transform: translateY(6px) translateX(1px) rotate(45deg);
    -webkit-transform: translateY(6px) translateX(1px) rotate(45deg);
    transform: translateY(6px) translateX(1px) rotate(45deg);
    background-color: #FFF;
  }
  
  .background-header .main-nav .menu-trigger.active span:before {
    background-color: #1e1e1e;
  }
  
  .header-area .main-nav .menu-trigger.active span:after {
    -moz-transform: translateY(-6px) translateX(1px) rotate(-45deg);
    -ms-transform: translateY(-6px) translateX(1px) rotate(-45deg);
    -webkit-transform: translateY(-6px) translateX(1px) rotate(-45deg);
    transform: translateY(-6px) translateX(1px) rotate(-45deg);
    background-color: #FFF;
  }
  
  .background-header .main-nav .menu-trigger.active span:after {
    background-color: #1e1e1e;
  }
  
  .header-area.header-sticky {
    min-height: 80px;
  }
  
  /* .header-area.header-sticky .nav {
    margin-top: 20px !important;
  } */
  
  .header-area.header-sticky .nav li a.active {
    color: #E12A31;
  }
  
  @media (max-width: 1200px) {
    .header-area .main-nav .nav li {
      // padding-left: 12px;
      // padding-right: 12px;
    }
    .header-area .main-nav:before {
      display: none;
    }
  }
  
  @media (max-width: 768px) {
    .header-area .main-nav .logo {
      color: #1e1e1e;
    }
    .header-area.header-sticky .nav li a:hover,
    .header-area.header-sticky .nav li a.active {
      color: #E12A31;
    }
    .header-area.header-sticky .nav li.search-icon a {
      width: 100%;
    }
    .header-area {
      padding: 0px 15px;
      height: 80px;
      box-shadow: none;
      text-align: center;
    }
    .header-area .container {
      padding: 0px;
    }
   
    .header-area .menu-trigger {
      display: block !important;
    }
    .header-area .main-nav {
      overflow: hidden;
    }
    .header-area .main-nav .nav {
      float: none;
      width: 100%;
      display: none;
      -webkit-transition: all 0s ease 0s;
      -moz-transition: all 0s ease 0s;
      -o-transition: all 0s ease 0s;
      transition: all 0s ease 0s;
      margin-left: 0px;
    }
  
    .header-area.header-sticky .nav {
      /* margin-top: 80px !important; */
      background: rgba(23, 23, 26, 0.9);
      border: 1px solid #272730;
      box-sizing: border-box;
      border-radius: 6px;  
    }
    .header-area .main-nav .nav li {
      width: 100%;
      padding: 5px 0;
      display: block;
    }
    .header-area .main-nav .nav li a {
      text-align: left;
      padding: 0px !important;
      border: none !important;
      color: #FFF !important;
    }
    .header-area .main-nav .nav li a:hover {
      /* background: #eee !important; */
      color: #E12A31 !important;
      font-weight: 500;
    }
    .header-area .main-nav .nav li.submenu ul {
      padding: 0 15px;
      width: 240px;
      position: relative;
      visibility: inherit;
      opacity: 1;
      z-index: 1;
      transform: translateY(0%);
      transition-delay: 0s, 0s, 0.3s;
      top: 0px;
      box-shadow: none;
      height: 0px;
      list-style-type: none;
    }
    .header-area .main-nav .nav li.submenu ul li a {
      font-size: 12px;
      font-weight: 400;
    }
    .header-area .main-nav .nav li.submenu ul li a:hover:before {
      width: 0px;
    }
    .header-area .main-nav .nav li.submenu ul.active {
      height: auto !important;
      width: 100%;
     
    }
    .header-area .main-nav .nav li.submenu:after {
      color: #3B566E;
      right: 25px;
      font-size: 14px;
      top: 15px;
    }
    // .header-area .main-nav .nav li.submenu:hover ul, .header-area .main-nav .nav li.submenu:focus ul {
    //   height: 0;
    //   padding: 0;
    // }
  }
  
  @media (min-width: 769px) {
    .header-area .main-nav .nav {
      display: flex !important;
    }
  }
  /* 
  ---------------------------------------------
  header
  --------------------------------------------- 
  */
  
  /* 
  ---------------------------------------------
  main head section
  --------------------------------------------- 
  */
  
  .main-head-section {
    height: calc(100vh - 80px);
    min-height: 600px;
    background: url(../assets/images/background/bg_img1.png);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    display: flex;
    align-items: center;
    @media (max-width: 1440px) {
      max-height: 600px;
      
    }
    // @media (max-width: 768px) {
    //   max-height: 600px;
    // }
  }
  .head-section-title {
    // margin-top: -80px;
    font-family: 'Jost';
    font-style: normal;
    font-weight: bold;
    font-size: 55px;
    line-height: 67px;
    text-align: center;
    color: #FFFFFF;
    }
    .red {
        color: #E12A31;
    }
    .italic {
      font-style: italic;
    }
    .head-section-description {
      justify-content: center;
      text-align: center;
      margin-top: 25px;
      font-family: 'Jost';
      font-style: normal;
      font-weight: normal;
      font-size: 22px;
      line-height: 32px;
      color: #FFFFFF;
      }
  
      .head-section-actions {
        margin-top: 60px;
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
      }
      .head-section-actions .head-section-action-item {
        cursor: pointer;
        background: #1A1A21;
        border-radius: 6px;
        padding: 19px 20px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        transition: all .3s ease-in-out;
      }
      .head-section-actions .head-section-action-item:nth-child(2) {
        margin: 0 30px;
      }
      .head-section-actions .head-section-action-item:hover {
        background: #E12A31;
        // box-shadow: 0px 0px 20px rgba(225, 42, 49, 0.4);
        border-radius: 6px;
        transition: all .3s ease-in-out;
      }
      .head-section-actions .head-section-action-item.active {
        background: #E12A31;
        transition: all .3s ease-in-out;
      }
  
      .head-section-actions .head-section-action-item .head-section-item-icon {
        margin-right: 20px;
      }
      .head-section-actions .head-section-action-item .head-section-item-details {
        display: flex;
        align-items: flex-start;
        flex-direction: column;
        justify-content: center;
      }
      .head-section-actions .head-section-action-item 
      .head-section-item-details .head-section-detals-title {
        font-family: 'Jost';
        font-style: normal;
        font-weight: bold;
        font-size: 22px;
        line-height: 32px;
        color: #FFFFFF;
  
      }
      .head-section-actions .head-section-action-item 
      .head-section-item-details .head-section-detals-description {
        font-family: 'Jost';
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 20px;
        color: #FFFFFF;
      }
  @media (max-width: 768px) {
    .main-head-section {
    background-size: cover;
    background-position: center;
    }
    .head-section-title {
      font-size: 45px;
      margin-top: 0;
      line-height: unset;
    }
    .head-section-description {
      flex-direction: column;
      margin-top: 20px;
      font-size: 25px;
    }
    .head-section-actions {
      margin-top: 15px;
    }
    .head-section-actions .head-section-action-item:nth-child(2), 
    .head-section-actions .head-section-action-item {
      margin: 10px 20px;
    }
  }
  @media (max-width: 500px) {
    .main-head-section {
      height: calc(100% - 80px);
      padding-top: 80px;
      padding-bottom: 30px;
    }
    .head-section-title {
      font-size: 30px;
      margin-top: 0;
      line-height: unset;
    }
    .head-section-description {
      flex-direction: column;
      margin-top: 14px;
      font-size: 18px;
    }
    .head-section-actions {
      margin-top: 10px;
    }
    .head-section-actions .head-section-action-item:nth-child(2), 
    .head-section-actions .head-section-action-item {
      margin: 5px 0;
      padding: 15px;
      width: 300px;
      display: flex;
      justify-content: flex-start; 
      .head-section-item-details {
        margin-left: 15px;
      }
    }
  }
  
  
  
  
  
  
  
  /* 
  ---------------------------------------------
  main head section
  --------------------------------------------- 
  */
  
  
  
  /* 
  ---------------------------------------------
  company section
  --------------------------------------------- 
  */
  
  .my-company-section {
    background-position: center;
    height: 80vh;
    background: url(../assets/images/background/bg_img_company.png);
    background-repeat: no-repeat;
    background-size: inherit;
    display: flex;
    align-items: center;
    @media (max-width: 500px) {
      background-size: contain;
    }
  }
  .my-company-section .company-section-title {
    text-align: left;
  }
  .my-company-section .company-section-description {
    margin-top: 25px;
    text-align: left;
    max-width: 510px;
    @media (max-width: 500px) {
      margin-bottom: 20px !important;
    }
  }
  .my-company-section .company-items {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr 1fr;
    gap: 0px 0px;
    grid-auto-flow: row;
    grid-template-areas:
      "company-item-1 company-item-2"
      "company-item-3 company-item-4";
  }
  
  .company-item-1 { grid-area: company-item-1; }
  
  .company-item-2 { grid-area: company-item-2; }
  
  .company-item-3 { grid-area: company-item-3; }
  
  .company-item-4 { grid-area: company-item-4; }
  
  
  .my-company-section .company-items .company-item {
    cursor: pointer;
    margin: 10px;
    padding: 35px 35px 35px 30px;
    background: #FFFFFF;
    border: 1px solid #E5E5E5;
    border-radius: 10px;
    height: 210px;
    transition: all .3s ease-in-out;
    &:hover {
      background: #E12A31;
      border: 1px solid #E12A31;
      transition: all .3s ease-in-out;
      .company-item-title {
        color: #FFF;
      }
      .company-item-description {
        color: #FFF;   
      }
    }
  }
  
//   .my-company-section .company-items .company-item .company-item-icon {
  
//   }
  .my-company-section .company-items .company-item .company-item-title {
    margin-top: 12px;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    color: #17171A
  }
  .my-company-section .company-items .company-item.active .company-item-title,
  .my-company-section .company-items .company-item.active .company-item-description {
    color: #FFFFFF;
  }
  .my-company-section .company-items .company-item .company-item-description {
    letter-spacing: 0.3px;
    margin-top: 10px;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    color: #17171A
  }
  
  @media (max-width: 768px) {
    .my-company-section {
      padding: 30px 0;
      height: unset;
    }
    .my-company-section .company-section-title {
      text-align: center;
    }
    .my-company-section .company-section-description {
      text-align: center;
      margin-top: 10px;
      margin: auto
    }
    .my-company-section .company-items .company-item {
      margin: 10px;
      padding: 20px;
      background: #FFFFFF;
      border: 1px solid #E5E5E5;
      border-radius: 10px;
      height: 160px;
    }
  
  }
  @media (max-width: 500px) {
    .my-company-section .company-items {
      display: grid;
      grid-template-columns: 1fr;
      grid-template-rows: 1fr 1fr 1fr 1fr;
      gap: 0px 0px;
      grid-auto-flow: row;
      grid-template-areas:
        "company-item-1"
        "company-item-2"
        "company-item-3"
        "company-item-4";
    }
    .my-company-section .company-items .company-item {
      height: unset;
      padding: 20px;
      min-height: 160px;
    }
  }
  
  /* 
  ---------------------------------------------
  company section
  --------------------------------------------- 
  */
  
  
  
  /* 
  ---------------------------------------------
  parcking section
  --------------------------------------------- 
  */
  .parcking-section {
    padding: 60px 0;
    background: url(../assets/images/background/bg_img_parcking.png);
    background-repeat: no-repeat;
    background-size: 100%;
    display: flex;
    align-items: center;
  }
  
  .parcking-section .parcking-section-title {
    color: white;
  }
//   .parcking-section .parcking-section-description {
  
//   }
  .parcking-section .parcking-section-cars-items {
    margin-top: 22px;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: 1fr 1fr;
    gap: 0px 0px;
    grid-auto-flow: row;
    grid-template-areas:
      "parcking-car-item-1 parcking-car-item-2 parcking-car-item-3"
      "parcking-car-item-4 parcking-car-item-5 parcking-car-item-6";
  }
  .parcking-section .parcking-section-cars-items .card-item {
    flex-direction: column;
    display: flex;
    align-items: center;
    justify-content: center;
    background: rgba(23, 23, 26, 0.7);
    border: 1px solid #272730;
    box-sizing: border-box;
    border-radius: 6px;
    height: 180px;
    margin: 11px;
    padding: 23px 32px;
  }
  .parcking-section .parcking-section-cars-items .card-item .card-item-title {
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 26px;
    color: #FFFFFF;
  }
  .parcking-section .parcking-section-cars-items .card-item .card-item-description {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
    text-align: center;
    color: #FFFFFF;
  }
  .parcking-section .parcking-section-cars-items .card-item .card-item-image {
    margin-top: 23px;
  }
  .parcking-section .parcking-section-cars-items .card-item .card-item-image img {
    width: 100%;
  }
  .parcking-car-item-1 { grid-area: parcking-car-item-1; }
  
  .parcking-car-item-2 { grid-area: parcking-car-item-2; }
  
  .parcking-car-item-3 { grid-area: parcking-car-item-3; }
  
  .parcking-car-item-4 { grid-area: parcking-car-item-4; }
  
  .parcking-car-item-5 { grid-area: parcking-car-item-5; }
  
  .parcking-car-item-6 { grid-area: parcking-car-item-6; }
  
  @media (max-width: 1024px) {
    .parcking-section {
      height: 100%;
      background-position: center;
      background-size: cover;
    }
    .parcking-section .parcking-section-cars-items {
      margin-top: 22px;
      display: grid;
      grid-template-columns: 1fr 1fr ;
      grid-template-rows: 1fr 1fr 1fr;
      gap: 0px 0px;
      grid-auto-flow: row;
      grid-template-areas:
        "parcking-car-item-1 parcking-car-item-2"
        "parcking-car-item-3 parcking-car-item-4"
        "parcking-car-item-5 parcking-car-item-6";
    }
  }
  @media (max-width: 768px) {
    .parcking-section {
      height: 100%;
      background-position: center;
      background-size: cover;
    }
    .parcking-section .parcking-section-cars-items {
      margin-top: 22px;
      display: grid;
      grid-template-columns: 1fr 1fr ;
      grid-template-rows: 1fr 1fr 1fr;
      gap: 0px 0px;
      grid-auto-flow: row;
      grid-template-areas:
        "parcking-car-item-1 parcking-car-item-2"
        "parcking-car-item-3 parcking-car-item-4"
        "parcking-car-item-5 parcking-car-item-6";
    }
    .parcking-section .parcking-section-cars-items .card-item {
      padding: 15px;
    }
  }
  
  
  @media (max-width: 500px) {
    
    .parcking-section .parcking-section-cars-items {
      margin-top: 22px;
      display: grid;
      grid-template-columns: 1fr;
      grid-template-rows: 1fr 1fr 1fr 1fr;
      gap: 0px 0px;
      grid-auto-flow: row;
      grid-template-areas:
        "parcking-car-item-1"
        "parcking-car-item-2"
        "parcking-car-item-3"
        "parcking-car-item-4"
        "parcking-car-item-5"
        "parcking-car-item-6";
    }
  }
  
  /* 
  ---------------------------------------------
  parcking section
  --------------------------------------------- 
  */
  
  
  
  
  
  
  /* 
  ---------------------------------------------
  company section
  --------------------------------------------- 
  */
  
  
  
  .company-section_v2 {
    height: 60vh;
    background: url(../assets/images/background/bg_img_company_v2.png);
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    display: flex;
    align-items: center;
  }
  
//   .company-section_v2 .company-section_v2-title {
  
//   }
  .company-section_v2 .company-section_v2-description {
    margin: auto;
    max-width: 840px;
    margin-top: 25px;
  }
  /* 
  ---------------------------------------------
  company section
  --------------------------------------------- 
  */
  
  
  /* 
  ---------------------------------------------
  Cooperation section
  --------------------------------------------- 
  */
  .cooperation-section {
    padding: 0 0 70px 0;
    background: linear-gradient(91.27deg, #17171A 0%, #303032 100%);
    &.dark {
      padding: 30px 0 40px 0;
    }
  }
  .cooperation-section .cooperation-section-title {
    font-style: normal;
    font-weight: bold;
    font-size: 32px;
    color: #FFFFFF;
  }
//   .cooperation-section .cooperation-slide-item {}
  .cooperation-section .cooperation-slide-item .cooperation-slide-item-title {
    font-style: normal;
    font-weight: bold;
    font-size: 22px;
    color: #FFFFFF;
  
  }
  .cooperation-section .cooperation-slide-item .cooperation-slide-item-description {
    margin-top: 10px;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    color: #FFFFFF;
  }
  
  .cooperation-arrows-box-custom {
    display: flex;
  }
  .cooperation-arrows-box-custom .cooperation-previous-link-custom {
    margin: 8px;
    width: 23px;
    display: flex;
    height: 23px;
    align-items: center;
    border: 2px solid #E12A31;
    justify-content: center;
    border-radius: 6px;
  
  }
  .cooperation-arrows-box-custom .cooperation-previous-link-custom svg {
    transform: rotate(180deg);
  }
  .cooperation-arrows-box-custom .cooperation-next-link-custom {
    margin: 8px;
    width: 23px;
    display: flex;
    height: 23px;
    align-items: center;
    border: 2px solid #E12A31;
    justify-content: center;
    border-radius: 6px;
    transition: all .3s ease-in-out;
  
  }
  .cooperation-arrows-box-custom .cooperation-next-link-custom.slick-disabled,
  .cooperation-arrows-box-custom .cooperation-previous-link-custom.slick-disabled {
    border: 2px solid #3d3e3f;
    transition: all .3s ease-in-out;
  }
  
  .cooperation-arrows-box-custom .cooperation-next-link-custom svg,
  .cooperation-arrows-box-custom .cooperation-previous-link-custom svg {
    stroke: #E12A31;
    transition: all .3s ease-in-out;
  }
  .cooperation-arrows-box-custom .cooperation-next-link-custom.slick-disabled svg,
  .cooperation-arrows-box-custom .cooperation-previous-link-custom.slick-disabled svg {
    stroke: #3d3e3f;
    transition: all .3s ease-in-out;
  }
  
  /* 
  ---------------------------------------------
  Cooperation section
  --------------------------------------------- 
  */
  
  
  
  
  
  
  
  
  /* 
  ---------------------------------------------
  sponsors section
  --------------------------------------------- 
  */
  
  
  
  .sponsors-section {
    padding: 80px 0;
  }
//   .sponsors-section .sponsors-section-sliders {}
  .sponsors-section .sponsors-section-sliders .sponsor-banner-item {
    display: flex;
    align-items: center;
    height: 160px;
    justify-content: center;
  }
  .sponsors-section .sponsors-section-sliders .sponsor-banner-item img {
    width: auto;
  }
  
  
  
  
  
  /* 
  ---------------------------------------------
  sponsors section
  --------------------------------------------- 
  */
  
  
  
  
  
  
  /* 
  ---------------------------------------------
  Footer section
  --------------------------------------------- 
  */
  
  .footer-section {
    background: linear-gradient(180deg, #0a0a0afa 40%,#4b1617 );
    padding: 30px 0;
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    display: flex;
    align-items: center;
  }
  .footer-section .footer-grid-container {
    width: 100%;
    display: grid; 
    grid-auto-columns: 1fr; 
    grid-template-columns: 1fr 1fr 1fr 1fr; 
    grid-template-rows: 1fr; 
    gap: 0px 0px; 
    grid-template-areas: 
      "footer-1 footer-1 footer-2 footer-3";
  }
  .footer-section .footer-grid-container .footer-title {
    max-width: 661px;
    font-style: normal;
    font-weight: normal;
    font-size: 22px;
    color: #FFFFFF;
  }
  .footer-section .footer-grid-container .footer-items {
    display: flex;
    flex-direction: column;
  }
  .footer-section .footer-grid-container .footer-item-title {
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    color: #FFFFFF;
    margin-bottom: 10px;
  }
  .footer-section .footer-grid-container .footer-item {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    color: #FFFFFF;
    transition: all .2s ease-in-out;
    margin-bottom: 5px;
  }
  .footer-section .footer-grid-container .footer-item:hover {
    transform: translateX(2px);
    color: #e12a31;
    font-weight: 400;
    transition: all .2s ease-in-out;
  }
  
  .footer-1 {
     grid-area: footer-1;
     display: flex;
     align-items: center;
   }
  
  .footer-2 { 
    display: flex;
    justify-content: center;
    grid-area: footer-2;
   }
  
  .footer-3 { 
    display: flex;
    justify-content: center;
    grid-area: footer-3;
   }
  
  .footer-4 { 
    display: flex;
    justify-content: center;
    grid-area: footer-4;
   }
  
  
  .footer-section .pre-footer {
    margin-top: 30px;
    width: 100%;
    display: flex;
    justify-content: space-between;
  }
  .footer-section .pre-footer .left-site-box {
    display: flex;
    align-items: center;
  }
  .footer-section .pre-footer .left-site-box img {
    margin-right: 15px;
  }
  .footer-section .pre-footer .left-site-box .copyright {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    color: #FFFFFF;
  }
  .footer-section .pre-footer .right-site-box {
    display: none;
  }
  .footer-section .pre-footer .right-site-box .pre-footer-item {
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 20px;
    text-align: center;
    color: #FFFFFF;
    margin-right: 45px;
    transition: all .2s ease-in-out;
  }
  
  
  
  @media (max-width: 1024px) {
    .footer-1 {
      display: flex;
      justify-content: center;
    }
    .footer-section .footer-grid-container {
      display: grid; 
      grid-auto-columns: 1fr; 
      grid-template-columns: 1fr 1fr 1fr; 
      grid-template-rows: 1fr 1fr; 
      gap: 0px 0px; 
      grid-template-areas: 
        "footer-1 footer-1 footer-1"
        "footer-2 footer-3 footer-4"; 
    }
    .footer-section .pre-footer {
      display: flex;
      flex-direction: column-reverse;
      align-items: center;
    }
    .footer-section .pre-footer .left-site-box {
       margin-top: 15px;
    }
  }
  
  @media (max-width: 500px) {
    .footer-1, .footer-2, .footer-3,.footer-4 {
      justify-content: flex-start;
      margin: 15px 0;
    }
    .footer-section .footer-grid-container {
      display: grid;
      grid-auto-columns: auto;
      grid-template-columns: auto;
      grid-template-rows: auto;
      gap: 0px 0px;
      grid-template-areas:
          "footer-1 footer-1"
          "footer-2 footer-3"
          ". footer-4";
    }
    .footer-section .pre-footer {
      display: flex;
      flex-direction: column;
      align-items: center;
    }
    .footer-section .pre-footer .left-site-box {
       margin-bottom: 15px;
    }
    .footer-section .pre-footer .right-site-box {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
    }
    .footer-section .pre-footer .right-site-box .pre-footer-item {
      margin: 10px 20px;
    }
  }
  
  
  
  
  
  
  
  .only-mob {
    display: none !important;
  }
  @media (max-width: 1024px) {
    .only-desk {
      display: none !important;
    }
    .only-mob {
      display: flex !important;
      justify-content: center;
    }
    .only-mob .nav-item-mob {
      margin: 10px;
    }
  }


  .vacansy {
    @media (max-width: 1024px) {
      padding-top: 80px;
      padding-bottom: 30px;
      max-height: unset;
    }
    .head-section-title {
      margin-top: 0;
      text-align: left;
      @media (max-width: 1024px) {
        text-align: center;
      }
    }
    .head-section-description {
      max-width: 510px;
      text-align: left;
      @media (max-width: 1024px) {
        max-width: 100%;
        text-align: center;
      }
    }
    .head-section-actions .head-section-action-item:nth-child(2),
    .head-section-actions .head-section-action-item {
      margin: 20px;
      @media (max-width: 1440px) {
        margin: 10px;
      }
    }
    .head-section-actions {
      @media (max-width: 1024px) {
       margin-top: 30px;
      }
      margin-top: 0;
      .head-section-action-item {
        background: rgba(23, 23, 26, 0.7);
        border: 1px solid #272730;
        border-radius: 6px;
        padding: 30px;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        &:hover {
          background: #E12A31;
        }
        &.active {
          background: #CA262B;
        }
        .vacansy-img {
          margin-bottom: 13px;
        }
        .vacansy-info {
          font-family: 'Jost';
          font-style: normal;
          font-weight: normal;
          font-size: 16px;
          color: #FFFFFF;
          max-width: 200px;
        }
        @media (max-width: 500px) {
          min-height: 175px;
          width: 100%;
          margin: 10px 0 !important;
        }
      }
    }


  }


  .vacansy-item-section {
    padding: 50px 0;
    .vacansy-item-card {
      margin: 20px 0;
      background: #FFFFFF;
      border-radius: 10px;
      padding: 30px;
      border: 1px solid #E5E5E5;
      &.active {
        border: 1px solid #E12A31;
      }
      &:hover {
        border: 1px solid #E12A31;
      }
      .vacansy-item-card-header {
        display: flex;
        justify-content: space-between;
      }
      .vacansy-item-card-preheader {
        margin-top: 5px;
        display: flex;
        justify-content: space-between;
        .vacansy-card-bold-title {
          font-style: normal;
          font-weight: bold;
          font-size: 18px;
          color: #E12A31;
        }
        .vacansy-card-bold-title-date {
          font-style: normal;
          font-weight: normal;
          font-size: 18px;
          color: #17171A;
        }

      }
     .vacansy-item-card-body {
       margin-top: 20px;
        .vacansy-content {
          margin-top: 5px;
          font-style: normal;
          font-weight: normal;
          font-size: 14px;
          color: #17171A;
        }
      }
      .vacansy-item-card-footer {
        margin-top: 20px;
        font-style: normal;
        font-weight: bold;
        font-size: 18px;
        text-decoration-line: underline;
        color: #E12A31;
      }
    }
  }
  .vacansy-name {
    font-family: "Jost";
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    color: #17171A;
    opacity: 0.5;
  }


  .white-bg-cooperation {
    background: #FFF;
    .white-bg-cooperation-border {
      padding: 35px 40px;
      border-radius: 10px;
      border: 1px solid #E5E5E5;
    }
    .cooperation-section-title {
      color: #17171A;
    }
    .cooperation-slide-item {
      .cooperation-slide-item-title {
        color: #E12A31;
      }
      .cooperation-slide-item-description {
        color: #17171A;
      }
    } 
    .cooperation-next-link-custom.slick-arrow.slick-disabled {
      opacity: .3;
    }
    .cooperation-arrows-box-custom .cooperation-previous-link-custom.slick-disabled {
      opacity: .3;
    }
  }


  @media (max-width: 1440px) {
    .freigth-carriages, .shipping, .rail,
    .transportation-of-oversized-cargo, .warehouse-services,
    .project-logistics, .courier-delivery {
    max-height: 590px;
    background-size: contain;
    }
  }
  @media (max-width: 1024px) {
    .freigth-carriages, .shipping, .rail,
    .transportation-of-oversized-cargo, .warehouse-services,
    .project-logistics, .courier-delivery {
    max-height: unset !important;
    background-size: cover !important;
    background-position: center !important;
    }
  }

  .freigth-carriages {
    background: linear-gradient(99.85deg, rgba(11, 11, 17, 0.91) 0%, rgba(23, 23, 26, 0.9) 100%, rgba(11, 11, 19, 0.74) 100%),
    url(../assets/images/background/freigth-carriages.png);
    background-repeat: no-repeat;
    background-size: cover;
    display: flex;
    align-items: center;
    .head-section-title {
      margin-top: 0;
    }
    .freigth-carriages-subtitle {
      margin: auto;
      margin-top: 20px;
      max-width: 713px;
      font-family: "Jost";
      font-style: normal;
      font-weight: normal;
      font-size: 18px;
      text-align: center;
      color: #FFFFFF;
      &.list {
        max-width: 500px;
        ul li {
          text-align: left;
          margin-top: 10px;
        }
      }
    }
  }

  .shipping {
    background: linear-gradient(99.85deg, rgba(11, 11, 17, 0.91) 0%, rgba(23, 23, 26, 0.9) 100%, rgba(11, 11, 19, 0.74) 100%),
    url(../assets/images/background/shipping.png);
    background-repeat: no-repeat;
    background-size: cover;
    display: flex;
    align-items: center;
    .head-section-title {
      margin-top: 0;
    }
    .shipping-subtitle {
      margin: auto;
      margin-top: 20px;
      max-width: 713px;
      font-family: "Jost";
      font-style: normal;
      font-weight: normal;
      font-size: 18px;
      text-align: left;
      color: #FFFFFF;
      &.list {
        max-width: 500px;
        ul li {
          text-align: left;
          margin-top: 10px;
        }
      }
    }
  }

  .rail {
    background: linear-gradient(99.85deg, rgba(11, 11, 17, 0.91) 0%, rgba(23, 23, 26, 0.9) 100%, rgba(11, 11, 19, 0.74) 100%),
    url(../assets/images/background/wagon-freight-train.png);
    background-repeat: no-repeat;
    background-size: cover;
    display: flex;
    align-items: center;
    @media (max-width: 1440px) {
      padding-top: 80px;
      padding-bottom: 40px;
    }
    .head-section-title {
      margin-top: 0;
    }
    .rail-subtitle {
      margin: auto;
      margin-top: 20px;
      max-width: 713px;
      font-family: "Jost";
      font-style: normal;
      font-weight: normal;
      font-size: 18px;
      text-align: left;
      color: #FFFFFF;
      &.list {
        max-width: 600px;
        ul li {
          text-align: left;
          margin-top: 10px;
        }
      }
    }
  }

  .transportation-of-oversized-cargo {
    background: linear-gradient(99.85deg, rgba(11, 11, 17, 0.91) 0%, rgba(23, 23, 26, 0.9) 100%, rgba(11, 11, 19, 0.74) 100%),
    url(../assets/images/background/oversized-heavy-transportations-truck.png);
    background-repeat: no-repeat;
    background-size: cover;
    display: flex;
    align-items: center;
    @media (max-width: 1440px) {
      padding-top: 80px;
      padding-bottom: 40px;
    }
    .head-section-title {
      margin-top: 0;
    }
    .transportation-of-oversized-cargo-subtitle {
      margin: auto;
      margin-top: 20px;
      max-width: 713px;
      font-family: "Jost";
      font-style: normal;
      font-weight: normal;
      font-size: 18px;
      text-align: center;
      color: #FFFFFF;
      &.list {
        max-width: 600px;
        ul li {
          text-align: left;
          margin-top: 10px;
        }
      }
    }
  }

  .warehouse-services {
    background: linear-gradient(99.85deg, rgba(11, 11, 17, 0.91) 0%, rgba(23, 23, 26, 0.9) 100%, rgba(11, 11, 19, 0.74) 100%),
    url(../assets/images/background/interior-storage-warehouse-with-shelves-full-goods.png);
    background-repeat: no-repeat;
    background-size: cover;
    display: flex;
    align-items: center;
    .head-section-title {
      margin-top: 0;
    }
    .warehouse-services-subtitle {
      margin: auto;
      margin-top: 20px;
      max-width: 819px;
      font-family: "Jost";
      font-style: normal;
      font-weight: normal;
      font-size: 18px;
      text-align: center;
      color: #FFFFFF;
      &.list {
        max-width: 600px;
        ul li {
          text-align: left;
          margin-top: 10px;
        }
      }
    }
  }

  .customs-clearance {
    background: linear-gradient(99.85deg, rgba(11, 11, 17, 0.91) 0%, rgba(23, 23, 26, 0.9) 100%, rgba(11, 11, 19, 0.74) 100%),
    url(../assets/images/background/portrait-successful-businessman-manager-ceo-holding-tablet-walking-through-warehouse-storage-area-looking-towards-shelves.png);
    background-repeat: no-repeat;
    background-size: cover;
    display: flex;
    align-items: center;
    .head-section-title {
      margin-top: 0;
    }
    .customs-clearance-subtitle {
      margin: auto;
      margin-top: 20px;
      max-width: 819px;
      font-family: "Jost";
      font-style: normal;
      font-weight: normal;
      font-size: 18px;
      text-align: center;
      color: #FFFFFF;
      &.list {
        max-width: 600px;
        ul li {
          text-align: left;
          margin-top: 10px;
        }
      }
    }
  }

  .cargo-insurance {
    background: linear-gradient(99.85deg, rgba(11, 11, 17, 0.91) 0%, rgba(23, 23, 26, 0.9) 100%, rgba(11, 11, 19, 0.74) 100%),
    url(../assets/images/background/hands-ceo-construction-company.png);
    background-repeat: no-repeat;
    background-size: cover;
    display: flex;
    align-items: center;
    .head-section-title {
      margin-top: 0;
    }
    .cargo-insurance-subtitle {
      margin: auto;
      margin-top: 20px;
      max-width: 819px;
      font-family: "Jost";
      font-style: normal;
      font-weight: normal;
      font-size: 18px;
      text-align: center;
      color: #FFFFFF;
      &.list {
        max-width: 600px;
        text-align: left;
        ul li {
          text-align: left;
          margin-top: 10px;
        }
      }
    }
  }

  .project-logistics {
    background: linear-gradient(99.85deg, rgba(11, 11, 17, 0.91) 0%, rgba(23, 23, 26, 0.9) 100%, rgba(11, 11, 19, 0.74) 100%),
    url(../assets/images/background/teamwork-brainstorming-meeting-new.png);
    background-repeat: no-repeat;
    background-size: cover;
    display: flex;
    align-items: center;
    .head-section-title {
      margin-top: 0;
    }
    .project-logistics-subtitle {
      margin: auto;
      margin-top: 20px;
      max-width: 819px;
      font-family: "Jost";
      font-style: normal;
      font-weight: normal;
      font-size: 18px;
      text-align: center;
      color: #FFFFFF;
      &.list {
        max-width: 600px;
        text-align: left;
        ul li {
          text-align: left;
          margin-top: 10px;
        }
      }
    }
  }

  .courier-delivery {
    background: linear-gradient(99.85deg, rgba(11, 11, 17, 0.91) 0%, rgba(23, 23, 26, 0.9) 100%, rgba(11, 11, 19, 0.74) 100%),
    url(../assets/images/background/full-shot-delivery-people-holding.png);
    background-repeat: no-repeat;
    background-size: cover;
    display: flex;
    align-items: center;
    .head-section-title {
      margin-top: 0;
    }
    .courier-delivery-subtitle {
      margin: auto;
      margin-top: 20px;
      max-width: 819px;
      font-family: "Jost";
      font-style: normal;
      font-weight: normal;
      font-size: 18px;
      text-align: center;
      color: #FFFFFF;
      &.list {
        max-width: 600px;
        text-align: left;
        ul li {
          text-align: left;
          margin-top: 10px;
        }
      }
    }
  }










  .advantage-section {
    padding: 40px 0;
    display: flex;
    align-items: center;
    .advantage-section-row {
      background: url(../assets/images/background/advantage.png);
      background-repeat: no-repeat;
      background-size: contain;
      .advantage-subtitle {
          padding: 40px 0 50px 0;
          margin: auto;
          max-width: 800px;
          font-family: "Jost";
          font-style: normal;
          font-weight: normal;
          font-size: 18px;
          text-align: center;
          color: #17171A;
      }
      .advantage-md-title {
        font-family: "Jost";
        font-style: normal;
        font-weight: bold;
        font-size: 22px;
        text-align: center;
        color: #E12A31;
      }
      .advantage-card-item {
        margin-top: 30px;
        background: #FFFFFF;
        border: 1px solid #E5E5E5;
        border-radius: 10px;
        padding: 30px;
        min-height: 150px;
        max-height: 150px;
        @media (max-width: 1024px) {
          padding: 15px;
        }
        @media (max-width: 768px) {
          min-height: unset;
        }
        .advantage-card-item-image {

        }
        .advantage-card-item-content {
          margin-top: 10px;
          max-width: 220px;
          font-style: normal;
          font-weight: normal;
          font-size: 14px;
          color: #17171A;
          letter-spacing: -.3px;
          @media (max-width: 768px) {
            max-width: 100%
          }
        }
      }

    }
  }




  .license {
    .head-section-title {
      margin-top: 0;
    }
    .license-logistics-subtitle {
      margin: auto;
      margin-top: 20px;
      max-width: 675px;
      font-family: "Jost";
      font-style: normal;
      font-weight: normal;
      font-size: 18px;
      text-align: center;
      color: #FFFFFF;
      &.list {
        max-width: 600px;
        text-align: left;
        ul li {
          text-align: left;
          margin-top: 10px;
        }
      }
    }
  }

  .license-items {
    padding: 45px 0;
    .license-card-item {
      margin: 10px auto;
      max-width: 365px;
      img {
        margin: auto;
        width: 100%;
      }
      .license-card-title {
        margin-top: 5px;
        font-family: "Jost";
        font-style: normal;
        font-weight: normal;
        font-size: 18px;
        text-align: center;
        color: #17171A;
      }
    }
    
  }


  .contact-us {
    max-height: 750px !important;
    @media (max-width: 1440px) {
      max-height: unset;
      padding-top: 80px;
      padding-bottom: 30px;
      height: 100%;
    }
    .contact-us-title {
      margin-top: unset !important;
    }
    .contact-us-info {
      .contact-info-title {
        font-family: "Jost";
        font-style: normal;
        font-weight: bold;
        font-size: 22px;
        color: #E12A31;
      }
    }
    .contact-us-content {
      max-width: 550px;
      margin-top: 17px;
      .contact-us-content-item {
        cursor: pointer;
        background: rgba(23, 23, 26, 0.7);
        border: 1px solid #272730;
        border-radius: 6px;
        margin-top: 10px;
        transition: all .2s ease-out;
        &:hover {
          background: #E12A31;
          box-shadow: 0px 0px 58px rgba(225, 42, 49, 0.2);
          transition: all .2s ease-out;
        }
        &.active {
          background: #E12A31;
          box-shadow: 0px 0px 58px rgba(225, 42, 49, 0.2);
          border-radius: 10px;
        }
        .management-card {
          padding: 25px 30px;
          display: flex;
          .management-card-item {
            width: 50%;
            .managment-card-item-info {
              display: flex;
              img {
                margin-right: 15px;
              }
              .managment-card-item-subtitle {
                font-style: normal;
                font-weight: bold;
                font-size: 18px;
                color: #FFFFFF;
                &.normal {
                  font-style: normal;
                  font-weight: normal;
                  font-size: 18px;
                  color: #FFFFFF;
                }
                &.ghost {
                  margin-left: 33px;
                  font-style: normal;
                  font-weight: normal;
                  font-size: 18px;
                  color: #FFFFFF;
                  opacity: 0.5;
                }
              }
            }
          }
        }
        
      }
    }
    .contact-us-content-requisites {
      min-height: 455px;
      margin-top: 17px;
      padding: 30px 50px;
      background: #FFFFFF;
      box-shadow: 10px 14px 44px rgba(0, 0, 0, 0.25);
      border-radius: 10px;
      .requisites-item {
        margin-bottom: 10px;
        position: relative;
        &:last-child {
          margin-bottom: 0;
        }
        .requisites-content {
          font-style: normal;
          font-weight: 400;
          font-size: 18px;
          color: #17171A;
        }
        .requisites-copy {
          cursor: pointer;
          position: absolute;
          right: 0;
          top: 0;
          .copy-link {
            font-style: normal;
            font-weight: normal;
            font-size: 14px;
            text-decoration-line: underline !important;
            color: #E12A31;
            margin-right: 5px;
          }
        }
      }
    }
  }


  .address-list {
    padding: 50px 0;
    @media (max-width: 500px) {
      padding: 25px 0;
    }
    .address-items {
      background: #FFFFFF;
      border: 1px solid #E5E5E5;
      border-radius: 10px;
      padding: 30px;
      margin: 20px;
      @media (max-width: 500px) {
        padding: 15px;
        margin: 10px 0;
      }
      .address-item {
        &:first-child {
          margin-top: 0;
        }
        margin-top: 10px;
        display: flex;
        align-items: center;
        img {
          margin-right: 10px;
        }
        .address-item-title {
          font-style: normal;
          font-weight: normal;
          font-size: 18px;
          line-height: normal;
          color: #17171A;
          &.red {
            color: #E12A31;
          }
        }
      }
    
    }
  }
  


  @media (max-width: 768px) {
    .contact-us {
      height: 100% !important;
      max-height: unset !important;
      padding-top: 80px;
      padding-bottom: 80px;
      .contact-info-title {
        margin-top: 15px;
        text-align: center;
      }
      .contact-us-content {
        margin-top: 15px;
        max-width: 100%;
        display: flex;
        flex-direction: column;
        .contact-us-content-item {
          .management-card {
            padding: 10px;
            .management-card-item {
                .managment-card-item-subtitle {
                  font-size: 14px !important;
                }
            }
          }
        }
      
      }
      .contact-us-content-requisites {
        padding: 10px;
        min-height: unset !important;
        .requisites-content {
          font-size: 14px !important;
        }
       }
    }
  }
  @media (max-width: 1246px) {
  .header-area .main-nav .nav .scroll-to-section, .submenu {
    padding-left: 10px !important;
    padding-right: 10px !important;
  }
}
  @media (max-width: 1024px) {
    .logo {
      margin-right: 0 !important;
      img {
        height: 40px;
      }
    }
    .main-nav {
      justify-content: flex-start !important;
    }
  }
  @media (max-width: 768px) {
  
    .menu-modal-dialog {
      .menu-modal-content {
        background: #17171A;
        border: 1px solid #272730;
        border-radius: 6px;
        .close {
          opacity: 1;
          // background: black;
        }
        .menu-modal-body {
          padding: 15px 19px;
          .auth-action {
            .auth-action-item {
              font-style: normal;
              font-weight: bold;
              font-size: 14px;
              color: #FFFFFF;
              text-align: left;
              padding-bottom: 10px;
              &.line {
                border-bottom: 1px solid #272730 !important;
              }
            }
          }
          .presco-menu-nav {
            padding: 0;
            list-style-type: none;
            margin: 0;
            .presco-menu-item {
              margin-top: 5px;
              &.submenu-container {
                .presco-submenu-card-body {
                  padding: 0;
                  background: transparent;
                  color: white;
                  .presco-sumbenu {
                    padding: 0 0 0 10px;
                    list-style-type: none;
                    margin: 0;
                    .presco-submenu-item {
                      line-height: 17px;
                      margin: 5px 0;
                      a {
                        font-style: normal;
                        font-weight: normal;
                        font-size: 12px;
                        color: #C4C4C4;
                      }
                    }
                  }
                }
              }
              .presco-menu-item-title {
                font-style: normal;
                font-weight: normal;
                font-size: 14px;
                text-align: left;
                color: #FFFFFF;
             
              }
            }

          }
        }
      }
    }
    .documents-items {
      .document-item {
        padding: 20px 30px;
        background: #FFFFFF;
        border: 1px solid #E5E5E5;
        border-radius: 10px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        .document-item-title {
          font-style: normal;
          font-weight: bold;
          font-size: 22px;
          text-align: center;
          color: #E12A31;
        }
        .document-item-image {

        }
      }
      
    }




 

    
    .logo {
      margin-right: 0 !important;
      img {
        height: 45px;
      }
    }
  }
  @media (max-width: 500px) {
    .logo {
      margin-right: 0 !important;
      img {
        height: 30px;
      }
    }
  }

  .toogle-menu-section {
    display: none;
  }
  @media (max-width: 768px) {
    .toogle-menu-section {
      position: absolute;
      right: 0;
      top: 0;
      height: 100%;
      display: flex;
      align-items: center;
      .toggle-swich-lang {
        margin-right: 12px;
      }
      .toggle-menu-button-menu {
  
      }
    }
  }

  .documents-items {
    .document-item {
      margin: 20px 0;
      cursor: pointer;
      padding: 20px 30px;
      background: #FFFFFF;
      border: 1px solid #E5E5E5;
      border-radius: 10px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      .document-item-title {
        font-style: normal;
        font-weight: bold;
        font-size: 22px;
        text-align: center;
        color: #E12A31;
      }
      .document-item-image {

      }
    }
    
  }