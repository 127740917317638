/* Slider */
.slick-slider {
    position: relative;
    display: block;
    box-sizing: border-box;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    -ms-touch-action: pan-y;
    touch-action: pan-y;
    -webkit-tap-highlight-color: transparent;
}
.slick-list {
    position: relative;
    overflow: hidden;
    display: block;
    margin: 0;
    padding: 0;
}
.slick-list:focus {
    outline: none;
}
.slick-list.dragging {
    cursor: pointer;
    cursor: hand;
}
.slick-slider .slick-track, .slick-slider .slick-list {
    -webkit-transform: translate3d(0, 0, 0);
    -moz-transform: translate3d(0, 0, 0);
    -ms-transform: translate3d(0, 0, 0);
    -o-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
}
.slick-track {
    position: relative;
    left: 0;
    top: 0;
    display: block;
}
.slick-track:before, .slick-track:after {
    content: "";
    display: table;
}
.slick-track:after {
    clear: both;
}
.slick-loading .slick-track {
    visibility: hidden;
}
.slick-slide {
    float: left;
    height: 100%;
    min-height: 1px;
    padding: 0 5px;
    display: none;
}
[dir="rtl"] .slick-slide {
    float: right;
}
.slick-slide img {
    display: block;
    width: 100%;
    height: auto;
}
.slick-slide.slick-loading img {
    display: none;
}
.slick-slide.dragging img {
    pointer-events: none;
}
.slick-initialized .slick-slide {
    display: block;
}
.slick-loading .slick-slide {
    visibility: hidden;
}
.slick-vertical .slick-slide {
    display: block;
    height: auto;
    border: 1px solid transparent;
}
.slick-arrow.slick-hidden {
    display: none;
}
.arrows-box-custom {
    text-align: center;
    padding: 10px;
}
.arrows-box-custom button {
    display: inline-block;
    vertical-align: top;
    padding: 5px 8px;
    margin: 2px;
    background: #000;
    color: #fff;
}
.slick-dots-custom {
    display: flex;
    padding: 10px;
    margin: 0;
    list-style: none;
    justify-content: center;
}
.slick-dots-custom li {
    padding: 2px;
}
.slick-dots-custom button {
    display: inline-block;
    background: #000;
    color: #fff;
    padding: 5px 8px;
}
