

@font-face {
    font-family: 'Jost';
    src: url('../assets/fonts/Jost-LightItalic.eot');
    src: local('Jost Light Italic'), local('Jost-LightItalic'),
        url('../assets/fonts/Jost-LightItalic.eot?#iefix') format('embedded-opentype'),
        url('../assets/fonts/Jost-LightItalic.woff2') format('woff2'),
        url('../assets/fonts/Jost-LightItalic.woff') format('woff'),
        url('../assets/fonts/Jost-LightItalic.ttf') format('truetype');
    font-weight: 300;
    font-style: italic;
}

@font-face {
    font-family: 'Jost';
    src: url('../assets/fonts/Jost-ExtraLight.eot');
    src: local('Jost ExtraLight'), local('Jost-ExtraLight'),
        url('../assets/fonts/Jost-ExtraLight.eot?#iefix') format('embedded-opentype'),
        url('../assets/fonts/Jost-ExtraLight.woff2') format('woff2'),
        url('../assets/fonts/Jost-ExtraLight.woff') format('woff'),
        url('../assets/fonts/Jost-ExtraLight.ttf') format('truetype');
    font-weight: 200;
    font-style: normal;
}

@font-face {
    font-family: 'Jost';
    src: url('../assets/fonts/Jost-Light.eot');
    src: local('Jost Light'), local('Jost-Light'),
        url('../assets/fonts/Jost-Light.eot?#iefix') format('embedded-opentype'),
        url('../assets/fonts/Jost-Light.woff2') format('woff2'),
        url('../assets/fonts/Jost-Light.woff') format('woff'),
        url('../assets/fonts/Jost-Light.ttf') format('truetype');
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: 'Jost';
    src: url('../assets/fonts/Jost-ExtraBoldItalic.eot');
    src: local('Jost ExtraBold Italic'), local('Jost-ExtraBoldItalic'),
        url('../assets/fonts/Jost-ExtraBoldItalic.eot?#iefix') format('embedded-opentype'),
        url('../assets/fonts/Jost-ExtraBoldItalic.woff2') format('woff2'),
        url('../assets/fonts/Jost-ExtraBoldItalic.woff') format('woff'),
        url('../assets/fonts/Jost-ExtraBoldItalic.ttf') format('truetype');
    font-weight: 800;
    font-style: italic;
}

@font-face {
    font-family: 'Jost';
    src: url('../assets/fonts/Jost-SemiBold.eot');
    src: local('Jost SemiBold'), local('Jost-SemiBold'),
        url('../assets/fonts/Jost-SemiBold.eot?#iefix') format('embedded-opentype'),
        url('../assets/fonts/Jost-SemiBold.woff2') format('woff2'),
        url('../assets/fonts/Jost-SemiBold.woff') format('woff'),
        url('../assets/fonts/Jost-SemiBold.ttf') format('truetype');
    font-weight: 600;
    font-style: normal;
}

@font-face {
    font-family: 'Jost';
    src: url('../assets/fonts/Jost-Regular.eot');
    src: local('Jost Regular'), local('Jost-Regular'),
        url('../assets/fonts/Jost-Regular.eot?#iefix') format('embedded-opentype'),
        url('../assets/fonts/Jost-Regular.woff2') format('woff2'),
        url('../assets/fonts/Jost-Regular.woff') format('woff'),
        url('../assets/fonts/Jost-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Jost';
    src: url('../assets/fonts/Jost-BoldItalic.eot');
    src: local('Jost Bold Italic'), local('Jost-BoldItalic'),
        url('../assets/fonts/Jost-BoldItalic.eot?#iefix') format('embedded-opentype'),
        url('../assets/fonts/Jost-BoldItalic.woff2') format('woff2'),
        url('../assets/fonts/Jost-BoldItalic.woff') format('woff'),
        url('../assets/fonts/Jost-BoldItalic.ttf') format('truetype');
    font-weight: bold;
    font-style: italic;
}

@font-face {
    font-family: 'Jost';
    src: url('../assets/fonts/Jost-ExtraBold.eot');
    src: local('Jost ExtraBold'), local('Jost-ExtraBold'),
        url('../assets/fonts/Jost-ExtraBold.eot?#iefix') format('embedded-opentype'),
        url('../assets/fonts/Jost-ExtraBold.woff2') format('woff2'),
        url('../assets/fonts/Jost-ExtraBold.woff') format('woff'),
        url('../assets/fonts/Jost-ExtraBold.ttf') format('truetype');
    font-weight: 800;
    font-style: normal;
}

@font-face {
    font-family: 'Jost';
    src: url('../assets/fonts/Jost-MediumItalic.eot');
    src: local('Jost Medium Italic'), local('Jost-MediumItalic'),
        url('../assets/fonts/Jost-MediumItalic.eot?#iefix') format('embedded-opentype'),
        url('../assets/fonts/Jost-MediumItalic.woff2') format('woff2'),
        url('../assets/fonts/Jost-MediumItalic.woff') format('woff'),
        url('../assets/fonts/Jost-MediumItalic.ttf') format('truetype');
    font-weight: 500;
    font-style: italic;
}

@font-face {
    font-family: 'Jost';
    src: url('../assets/fonts/Jost-ExtraLightItalic.eot');
    src: local('Jost ExtraLight Italic'), local('Jost-ExtraLightItalic'),
        url('../assets/fonts/Jost-ExtraLightItalic.eot?#iefix') format('embedded-opentype'),
        url('../assets/fonts/Jost-ExtraLightItalic.woff2') format('woff2'),
        url('../assets/fonts/Jost-ExtraLightItalic.woff') format('woff'),
        url('../assets/fonts/Jost-ExtraLightItalic.ttf') format('truetype');
    font-weight: 200;
    font-style: italic;
}

@font-face {
    font-family: 'Jost';
    src: url('../assets/fonts/Jost-Italic.eot');
    src: local('Jost Italic'), local('Jost-Italic'),
        url('../assets/fonts/Jost-Italic.eot?#iefix') format('embedded-opentype'),
        url('../assets/fonts/Jost-Italic.woff2') format('woff2'),
        url('../assets/fonts/Jost-Italic.woff') format('woff'),
        url('../assets/fonts/Jost-Italic.ttf') format('truetype');
    font-weight: normal;
    font-style: italic;
}

@font-face {
    font-family: 'Jost';
    src: url('../assets/fonts/Jost-Black.eot');
    src: local('Jost Black'), local('Jost-Black'),
        url('../assets/fonts/Jost-Black.eot?#iefix') format('embedded-opentype'),
        url('../assets/fonts/Jost-Black.woff2') format('woff2'),
        url('../assets/fonts/Jost-Black.woff') format('woff'),
        url('../assets/fonts/Jost-Black.ttf') format('truetype');
    font-weight: 900;
    font-style: normal;
}

@font-face {
    font-family: 'Jost';
    src: url('../assets/fonts/Jost-Bold.eot');
    src: local('Jost Bold'), local('Jost-Bold'),
        url('../assets/fonts/Jost-Bold.eot?#iefix') format('embedded-opentype'),
        url('../assets/fonts/Jost-Bold.woff2') format('woff2'),
        url('../assets/fonts/Jost-Bold.woff') format('woff'),
        url('../assets/fonts/Jost-Bold.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'Jost';
    src: url('../assets/fonts/Jost-BlackItalic.eot');
    src: local('Jost Black Italic'), local('Jost-BlackItalic'),
        url('../assets/fonts/Jost-BlackItalic.eot?#iefix') format('embedded-opentype'),
        url('../assets/fonts/Jost-BlackItalic.woff2') format('woff2'),
        url('../assets/fonts/Jost-BlackItalic.woff') format('woff'),
        url('../assets/fonts/Jost-BlackItalic.ttf') format('truetype');
    font-weight: 900;
    font-style: italic;
}

@font-face {
    font-family: 'Jost';
    src: url('../assets/fonts/Jost-Medium.eot');
    src: local('Jost Medium'), local('Jost-Medium'),
        url('../assets/fonts/Jost-Medium.eot?#iefix') format('embedded-opentype'),
        url('../assets/fonts/Jost-Medium.woff2') format('woff2'),
        url('../assets/fonts/Jost-Medium.woff') format('woff'),
        url('../assets/fonts/Jost-Medium.ttf') format('truetype');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'Jost';
    src: url('../assets/fonts/Jost-Thin.eot');
    src: local('Jost Thin'), local('Jost-Thin'),
        url('../assets/fonts/Jost-Thin.eot?#iefix') format('embedded-opentype'),
        url('../assets/fonts/Jost-Thin.woff2') format('woff2'),
        url('../assets/fonts/Jost-Thin.woff') format('woff'),
        url('../assets/fonts/Jost-Thin.ttf') format('truetype');
    font-weight: 100;
    font-style: normal;
}

@font-face {
    font-family: 'Jost';
    src: url('../assets/fonts/Jost-SemiBoldItalic.eot');
    src: local('Jost SemiBold Italic'), local('Jost-SemiBoldItalic'),
        url('../assets/fonts/Jost-SemiBoldItalic.eot?#iefix') format('embedded-opentype'),
        url('../assets/fonts/Jost-SemiBoldItalic.woff2') format('woff2'),
        url('../assets/fonts/Jost-SemiBoldItalic.woff') format('woff'),
        url('../assets/fonts/Jost-SemiBoldItalic.ttf') format('truetype');
    font-weight: 600;
    font-style: italic;
}

@font-face {
    font-family: 'Jost';
    src: url('../assets/fonts/Jost-ThinItalic.eot');
    src: local('Jost Thin Italic'), local('Jost-ThinItalic'),
        url('../assets/fonts/Jost-ThinItalic.eot?#iefix') format('embedded-opentype'),
        url('../assets/fonts/Jost-ThinItalic.woff2') format('woff2'),
        url('../assets/fonts/Jost-ThinItalic.woff') format('woff'),
        url('../assets/fonts/Jost-ThinItalic.ttf') format('truetype');
    font-weight: 100;
    font-style: italic;
}

