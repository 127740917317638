.presco-modal-content {
    background: #FFFFFF !important;
    border-radius: 10px !important;
    padding: 30px 40px !important;
    @media (max-width: 500px) {
        padding: 20px !important;
    }
    @media (min-width: 768px) {
        width: 530px;
    }
    .presco-modal-header {
        flex-direction: column;
        display: flex;
        justify-content: center;
        .modal-logo {
            height: 30px;
        }
        .presco-header-title {
            font-family: "Jost";
            font-style: normal;
            font-weight: 600;
            font-size: 18px;
            text-align: center;
            color: #E12A31;
            margin-top: 15px;
        }
        .presco-header-subtitle {
            margin-top: 5px;
            font-family: "Jost";
            font-style: normal;
            font-weight: normal;
            font-size: 14px;
            text-align: center;
            color: #17171A;
        }
    }
    .presco-modal-body {
     
    }
    .presco-modal-footer {
        margin-top: 20px;
        display: flex;
        justify-content: center;
        .presco-modal-btn {
            height: 40px;
            width: 210px;
            background: #C4C4C4;
            border-radius: 10px;
            font-family: "Jost";
            font-style: normal;
            font-weight: 500;
            font-size: 14px;
            text-align: center;
            color: #FFFFFF;
        }
    }
}

.presco-input:focus ~ .presco-label {
    color: #E12A31; 
    transition: all .3s ease-out;
} 
.presco-label {
    position: absolute;
    left: 25px;
    top: 10px;
    font-family: "Jost" !important;
    font-weight: normal;
    font-size: 14px;
    color: #17171A;
    transition: all .3s ease-out;
   
}
.was-validated {
    .presco-input:invalid {
       background: #FFF3F3 !important;
       border: 1px solid #E12A31  !important;
    }
    .presco-input:invalid ~ .presco-label {
       color: #E12A31 !important;
    }
    .presco-input:valid {
        
        // background: #FFF3F3 !important;
        // border: 1px solid #E12A31  !important;
     }
     .presco-input:valid ~ .presco-label {
        color: #C4C4C4 !important;
     }

   
}
.presco-input {
    width: 100% !important;
    height: 65px !important;
    background: #FFFFFF !important;
    border: 1px solid #E5E5E5 !important;
    box-sizing: border-box !important;
    border-radius: 10px !important;
    padding: 30px 25px 9px 25px !important;
    font-family: "Jost" !important;
    font-weight: normal !important;
    font-size: 18px !important;
    color: #17171A !important;
    transition: all .3s ease-out !important;
   
    &.textarea {
        height: unset !important;
    }
    &:focus-visible {
        outline: none;
      }
    &:focus {
        border: 1px solid #E12A31;
        transition: all .3s ease-out;
    }


    &.tel-input {
        padding-left: 90px;
        display: flex;
        align-items: flex-end; 
    }
}
.presco-tel-input {
    padding-left: 90px;
}
.presco-tel {
    left: 25px;
    bottom: 9px;
    display: flex;
    position: absolute;
    font-family: "Jost" !important;
    font-weight: normal;
    font-size: 18px;
    color: #17171A;
    transition: all .3s ease-out;
    line-height: normal;
    img {
        margin-right: 6px;
    }
}



.attach-file {
    background: #FFFFFF;
    border: 1px dashed #E5E5E5;
    border-radius: 10px;
    position: relative;
    display: flex;
    justify-content: flex-end;
    padding: 0 20px;
    height: 65px;
    .attach-file-text {
        top: 10px;
        left: 20px;
        position: absolute;
        font-family: "Jost";
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        color: #17171A;
    }
    .attach-file-icon {
        display: flex;
        align-items: center;
    }
}

.presco-modal-dialog {
    height: 100%;
    display: flex;
    align-items: center;
}

.close {
    z-index: 99999;
    cursor: pointer;
    position: absolute;
    right: 0;
    top: -40px;
    opacity: 1 !important;
}

@media (min-width: 576px) {
.modal-dialog {
    max-width: 550px !important;
    margin: auto !important;
}
}

.forgot-pass {
    font-family: "Jost";
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    text-decoration-line: underline !important;
    color: #E12A31;
}